import React, { Component } from 'react';
import { useContext, useEffect, useState } from "react";
import { Card, Accordion, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import bar_img_white from "../../images/bar_img_white.png"



// import 'font-awesome/css/font-awesome.min.css';




import settingsicon from "../../images/settings_icon.png"
import kycicon from "../../images/kyc.svg"
import launchpadicon from "../../images/launchpad.svg"
import privateicon from "../../images/private.svg"

import adminlaunchpad from "../../images/adminlaunchpad.png"
import trending from "../../images/trending.svg"
import airicon from "../../images/Bitdeal/Air.svg";
import adminsettings from "../../images/admin_settings.png"
import restrictIP from "../../images/restrictIP.png"
import feeSettings from "../../images/feeSettings.png"
import createlaunch from "../../images/createlaunch.png"
import createfair from "../../images/createfair.png"



import audit from "../../images/audit.png"

import launchpadiconcreate from "../../images/launchpad1.svg"





import { NavLink } from "react-router-dom";

class Adminsidebar extends Component {

    constructor(props) {
        super(props);
        this.state = {
            location: false,
            sidebarShrink: false,
            setActive: 0
        }
    }

    setSidebarWidth() {
        this.setState({ sidebarShrink: !this.state.sidebarShrink });
        if (this.state.sidebarShrink) {
            document.body.classList.remove('sidebar_shr');
        }
        else {
            document.body.classList.add('sidebar_shr');

        }
    }






    render() {
        const { sidebarShrink, setActive } = this.state



        return (
            <>

                <div id="sidebar" className={sidebarShrink ? "side_shrink lis_paren_side_admn" : "lis_paren_side_admn"}>

                    <button className="get-started-btn rounded_btn_wal shrink_side_btn d-none d-lg-block" onClick={() => this.setSidebarWidth()}>
                        <img src={bar_img_white} className='img-fluid' />
                        {/* <i class="fa fa-chevron-right" aria-hidden="true"></i> */}
                    </button>
                    <ul className='sidebar_ul'>
                        <NavLink to="/adminlaunchpad" className="parent_1_grad">
                            <li className='lis_paren_side li_bot_less'>

                                <img src={launchpadicon} className="icon_sidebar" />
                                <span>
                                    Launchpad
                                </span>

                            </li>


                        </NavLink>

                        {/* <NavLink to="/createadmin" className="parent_1_grad">
                            <li className='lis_paren_side li_bot_less'>

                                <img src={createlaunch} className="icon_sidebar" />
                                <span>
                                    Create Launchpad
                                </span>

                            </li>


                        </NavLink>

                        <NavLink to="/createfairlaunchadmin" className="parent_1_grad">
                            <li className='lis_paren_side li_bot_less'>

                                <img src={createfair} className="icon_sidebar" />
                                <span>
                                    Create Fairlaunch
                                </span>

                            </li>


                        </NavLink>

                        <NavLink to="/createprivatesaleadmin" className="parent_1_grad">
                            <li className='lis_paren_side li_bot_less'>

                                <img src={privateicon} className="icon_sidebar" />
                                <span>
                                    Create PrivateSale
                                </span>

                            </li>


                        </NavLink> */}

                        {/* <NavLink to="/adminairdrop" className="parent_1_grad">
                            <li className='lis_paren_side li_bot_less'>

                                <img src={airicon} className="icon_sidebar" />
                                <span>
                                    Airdrop
                                </span>

                            </li>


                        </NavLink> */}

                        <NavLink to="/adminkyc" className="parent_1_grad">
                            <li className='lis_paren_side li_bot_less'>

                                <img src={kycicon} className="icon_sidebar" />
                                <span>
                                    KYC
                                </span>

                            </li>
                        </NavLink>

                        <NavLink to="/adminsettings" className="parent_1_grad">
                            <li className='lis_paren_side li_bot_less'>

                                <img src={settingsicon} className="icon_sidebar" />
                                <span>
                                    Settings
                                </span>

                            </li>
                        </NavLink>

                        <NavLink to="/launchpadsettings" className="parent_1_grad">
                            <li className='lis_paren_side li_bot_less'>

                                <img src={adminlaunchpad} className="icon_sidebar" />
                                <span>
                                    Launchpad Settings
                                </span>

                            </li>
                        </NavLink>

                        <NavLink to="/admintrending" className="parent_1_grad">
                            <li className='lis_paren_side li_bot_less'>

                                <img src={trending} className="icon_sidebar" />
                                <span>
                                    OnTop
                                </span>

                            </li>
                        </NavLink>

                        <NavLink to="/adminaudit" className="parent_1_grad">
                            <li className='lis_paren_side li_bot_less'>

                                <img src={audit} className="icon_sidebar" />
                                <span>
                                    Audit/KYC
                                </span>

                            </li>
                        </NavLink>

                        <NavLink to="/settings" className="parent_1_grad">
                            <li className='lis_paren_side li_bot_less'>

                                <img src={adminsettings} className="icon_sidebar" />
                                <span>
                                    Profile Settings
                                </span>

                            </li>
                        </NavLink>

                        {/* <NavLink to="/restrictIp" className="parent_1_grad">
                            <li className='lis_paren_side li_bot_less'>

                                <img src={restrictIP} className="icon_sidebar" />
                                <span>
                                    Restrict IP
                                </span>

                            </li>
                        </NavLink> */}



                        <NavLink to="/feeSettings" className="parent_1_grad">
                            <li className='lis_paren_side li_bot_less'>

                                <img src={feeSettings} className="icon_sidebar" />
                                <span>
                                    Fee Settings
                                </span>

                            </li>
                        </NavLink>

                        {/* <NavLink to="/admincreatelaunchpad" className="parent_1_grad">
                <li className='lis_paren_side li_bot_less'>
               
                <img src={launchpadiconcreate} className="icon_sidebar" />
                <span>
                    Create Launchpad
                </span>
               
                </li>
                </NavLink>  */}



                    </ul>


                </div>

                <div id="footer_side">
                    <div className='footer_menu_flex_side footer_menu_flex_side_admin'>



                        <button className="btn_bar_mobl" onClick={() => this.setSidebarWidth()}>
                            <img src={bar_img_white} className='img-fluid img_bar' />
                            {/* <i class="fa fa-bars" aria-hidden="true"></i> */}
                        </button>
                    </div>
                </div>

            </>
        );
    }
}



export default Adminsidebar