import React, { Component } from 'react';
import { useContext, useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import Cookies from 'universal-cookie';
import toast, { Toaster } from 'react-hot-toast';

import Adminheader from './Adminheader';
import Adminsidebar from './Adminsidebar';
import EditFeemodal from "./EditFeemodal";
import AddFeemodal from './AddFeemodal';
import DeleteFeemodal from './deleteFeemodal'

import Web3 from "web3";
import '../../css/styles.css';

import coin from "../../images/coin.png"
import favicon from "../../images/Bitdeal/logo_curve.png"

import loader from "../../images/loader.gif"
import { Dropdown, Nav, Tab, Row, Col, Container, ProgressBar, InputGroup, Form } from 'react-bootstrap';
import { editFee, deleteFee, getFee } from '../../hooks/usebackend';

const cookies = new Cookies();
class AdminFee extends Component {

    showLoader() {
        document.getElementsByTagName("body")[0].classList.add("overflow_hidden");
        document.getElementById("logo_overlay").style.opacity = 0.05;
        document.getElementById("loader_div").classList.remove("d-none");
        document.getElementById("loader_div").classList.add("d-block");


    }

    hideLoader() {
        document.getElementsByTagName("body")[0].classList.remove("overflow_hidden");
        document.getElementById("logo_overlay").style.opacity = 1;
        document.getElementById("loader_div").classList.remove("d-block");
        document.getElementById("loader_div").classList.add("d-none");



    }

    componentDidMount() {
        this.restrict()
        this.getFeeSettings();
        this.hideLoader();

    }

    restrict = async () => {
        var email = cookies.get('cryp-launch-admin-email');
        console.log("email", email);
        if (!email) {
            this.props.history.push("/login")
        }
    }



    constructor(props) {
        super(props);
        this.state = {
            addFeeModal: false,
            editFeeModal: false,
            deleteFeeModal: false,
            getFee: [],
            skip: 0,
            limit: 10,
            pagecount: 0,
            button: 0,
            currentpage: 1,
            deleteId: "",
            editId: {},
            stepcount: 5,
            settings: [],
            singlesetting: {},
        };
    }

    getFeeSettings = async () => {

        let get = await getFee();
        console.log("getFee", get);
        if (get?.data?.data?.status == true) {
            this.setState({ "getFee": get?.data?.data?.data });
            console.log("getFee_getFee", get?.data?.data?.data)
        }
    }

    deleteFeeSettings = async (Id) => {
        console.log("id", Id)
        let deletedata = await deleteFee(Id);
        console.log("deletedata", deletedata);
        if (deletedata?.data?.data?.status == true) {
            toast.success(deletedata?.data?.data?.message)
            this.setState({ deleteFeeModal: false });
            this.getFeeSettings()
        }
    }


    onDismissAdd() {
        this.setState({ addFeeModal: false });
    }

    onDismissDelete() {
        this.setState({ deleteFeeModal: false });
    }

    onDismissEdit() {
        this.setState({ editFeeModal: false });
    }


    render() {


        const location = this.props.location.pathname.split('/')[1];

        const { addFeeModal, editFeeModal, deleteFeeModal } = this.state

        return (
            <div id="loader_main">
                <div id="loader_div">
                    <span className="spin_round">

                    </span>
                    <img src={favicon} className="logo_load" />
                </div>
                <div className='logo_overlay' id="logo_overlay">


                    <Adminheader />

                    <div className="whole_sec pb-5">
                        <div className='flex_side_right'>
                            <Adminsidebar />
                            {/* ongoing_sec */}
                            <div className='right_side_sec'>

                                <div className="right_side_spacing">
                                    <div className='ongoing_sec proj_bg pb-5'>
                                        <div className="container px-1">
                                            <div className='top_heqad_sec mt-5 mt-md-0'>

                                                <div className="row">
                                                    <div className="col-12 col-md-6 flex_cen_col mt-md-5 mb-md-4">
                                                        <p className="banner_subtitle form_subhead">Fee Settings</p>




                                                        {/* <hr className='hr_yellow mt-5'/> */}
                                                    </div>
                                                    <div className="col-12 col-md-6 flex_cen_col mt-md-5 mb-md-4">
                                                        <div className='text-right'>
                                                            <button className="get-started-btn btn_width_auto" onClick={() => this.setState({ addFeeModal: true })}>Add</button>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>

                                        <div className="inner_bg">
                                            <div className="container px-1">
                                                <Tab.Container defaultActiveKey="second">
                                                    <Row className="container-fluid w-100 mx-0 px-0">


                                                        <Col lg={12} className="img_center_lg px-0">
                                                            <div className='container container_custom px-0'>
                                                                <Tab.Content>

                                                                    <Tab.Pane eventKey="second">

                                                                        <div class="table-responsive">
                                                                            {console.log("this.state.getIp", this.state.getFee && this.state.getFee?.length > 0, this.state.getFee)}
                                                                            {this.state.getFee && this.state.getFee?.length > 0 ?
                                                                                <table className="table recepients_table ip_table_size">
                                                                                    <thead>
                                                                                        <tr>
                                                                                            <th>Fee Name</th>
                                                                                            <th>BNB value</th>
                                                                                            <th>Token value</th>
                                                                                            <th>Actions</th>
                                                                                        </tr>
                                                                                    </thead>
                                                                                    {this.state.getFee && this.state.getFee?.length > 0 && this.state.getFee?.map((data, i) => <>
                                                                                        <tr>
                                                                                            <td>


                                                                                                <div className="p-0 table_det">
                                                                                                    <span className="table_text">{data.feeName}</span>

                                                                                                </div>
                                                                                            </td>
                                                                                            <td>
                                                                                                <div className="p-0 table_det">
                                                                                                    <span className="table_text">{data.BNBvalue}</span>

                                                                                                </div>
                                                                                            </td>
                                                                                            <td>
                                                                                                <div className="p-0 table_det">
                                                                                                    <span className="table_text">{data.tokenvalue}</span>

                                                                                                </div>
                                                                                            </td>


                                                                                            <td className=""><a className="link_text" onClick={() => {
                                                                                                // this.setState({ "singlesetting": data })
                                                                                                this.setState({ editFeeModal: true, editId: data });
                                                                                            }
                                                                                            }>Edit</a>

                                                                                                <a className="link_text ml-3" onClick={() => {
                                                                                                    // this.setState({ "singlesetting": data })
                                                                                                    this.setState({ deleteFeeModal: true, deleteId: data._id });
                                                                                                }
                                                                                                }>Delete</a>
                                                                                            
                                                                                            </td>
                                                                                            {/* <td className=""><a className="link_text" onClick={() => {
                                                                                         
                                                                                                this.setState({ deleteFeeModal: true, deleteId: data._id });
                                                                                            }
                                                                                            }>Delete</a></td> */}
                                                                                        


                                                                                        </tr>
                                                                                    </>
                                                                                    )}




                                                                                </table> : <p className='no_data_text'>No Data Found</p>}
                                                                        </div>



                                                                        {!this.state.settings?.length == 0 &&
                                                                            <div className="mt-2 d-flex justify-content-center align-items-center btn_pagin_wrap">
                                                                                <button className="pagination_btn"
                                                                                    onClick={this.handleprevpage}
                                                                                    disabled={this.state.currentpage == 1}
                                                                                ><i class="fa fa-angle-left" aria-hidden="true"

                                                                                ></i></button>


                                                                                {[...Array(this.state.stepcount)].map((val, i) => {
                                                                                    if ((this.state.button + i + 1) <= this.state.pagecount)
                                                                                        return (
                                                                                            <button className={this.state.currentpage == (this.state.button + i + 1) ? "pagination_btn active" : "pagination_btn"} value={this.state.button + i + 1} onClick={
                                                                                                (e) => {
                                                                                                    console.log("e", e?.target?.value);
                                                                                                    this.handlenumpage(e?.target?.value)
                                                                                                }
                                                                                            }>{this.state.button + i + 1}</button>
                                                                                        )
                                                                                }

                                                                                )}


                                                                                <button className="pagination_btn"
                                                                                    onClick={this.handlenextpage}
                                                                                    disabled={this.state.currentpage == this.state.pagecount}
                                                                                ><i class="fa fa-angle-right" aria-hidden="true"

                                                                                ></i></button>
                                                                            </div>}
                                                                    </Tab.Pane>


                                                                </Tab.Content>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Tab.Container>


                                            </div>
                                        </div>
                                    </div>
                                    {/* end ongoing_sec */}

                                </div>
                            </div>
                        </div>
                    </div>
                    {addFeeModal && <AddFeemodal onDismiss={() => this.onDismissAdd()} getFee={() => this.getFeeSettings()} />}
                    {editFeeModal && <EditFeemodal onDismiss={() => this.onDismissEdit()} editFee={this.state.editId} getFee={() => this.getFeeSettings()} />}
                    {deleteFeeModal && <DeleteFeemodal onDismiss={() => this.onDismissDelete()} OnDelete={() => { this.deleteFeeSettings(this.state.deleteId) }} />}


                    {/* <Footer /> */}
                </div>
            </div>
        )
    }
}

export default AdminFee